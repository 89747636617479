<template>
  <v-app id="error" fill-height fluid>
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex xs12 sm5 md5>
          <v-row justify="center">
            <v-col cols="9" xl="9" lg="9" class="text-center">
              <IconAlert width="100px" class="mb-6" />
              <br />
              <Logo width="400px" :dark="false" />
              <h1 class="mt-3">{{ title }}</h1>
              <h2 class="mb-4">
                {{ description }}
              </h2>
              <h2 v-if="type" class="mb-4">{{ localTime }} seg.</h2>
              <v-btn
                v-if="type"
                color="secondary"
                class="mt-4 text-uppercase"
                depressed
                large
                @click.stop="login()"
              >
                <v-icon class="mr-2">fa-solid fa-right-to-bracket</v-icon>
                {{ descLogin }}
              </v-btn>
              <v-btn
                v-if="!type"
                color="secondary"
                class="mt-4 text-uppercase"
                depressed
                :to="{ name: 'Home' }"
                large
              >
                <v-icon class="mr-2"> fa-solid fa-arrow-left </v-icon>
                {{ descBack }}
              </v-btn>
            </v-col>
          </v-row>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>
<script>
import { mapGetters } from "vuex";
import i18n from "@/plugins/i18n";

export default {
  metaInfo: () => ({
    title: `Error | ${process.env.VUE_APP_NAME}`,
  }),
  data: () => ({
    localTime: "5",
  }),
  computed: {
    ...mapGetters({
      token: "auth/token",
    }),
    tokenGetters(){
      console.log(this.token);
      return this.token
    },
    type() {
      return this.$route !== undefined ? this.$route.params.type : "";
    },
    title() {
      return this.type
        ? i18n.t("views.noPageFound.info_title_expired")
        : i18n.t("views.noPageFound.info_title_error");
    },
    description() {
      return this.type
        ? i18n.t("views.noPageFound.info_desc_expired")
        : i18n.t("views.noPageFound.info_desc_error");
    },
    descLogin() {
      return i18n.t("views.noPageFound.desc_login");
    },
    descBack() {
      return i18n.t("views.noPageFound.desc_back");
    },
  },
  created() {
    if (!this.tokenGetters) {
      if (this.type) {
        this.showLocaleTime();
      } else {
        this.login();
      }
    } else if (this.$route.path === "/expired" && this.tokenGetters) {
      this.$router.push({ name: "Home" });
    }
  },
  components: {
    Logo: () => import(/* webpackChunkName: "Logo" */ "@/components/assets/Logo.vue"),
    IconAlert: () =>
      import(/* webpackChunkName: "IconAlert" */ "@/components/assets/IconAlert.vue"),
  },
  methods: {
    showLocaleTime() {
      let _this = this;
      setInterval(function () {
        _this.localTime--;
        if (_this.localTime == 0) {
          _this.login();
        }
      }, 1000);
    },
    login() {
      window.location.href = process.env.VUE_APP_URL_LOGIN_SUITE;
    },
  },
};
</script>
<style scoped>
#error {
  font-family: Krub;
  color: #fff;
}

#error {
  background-color: #0b0045;
}

h2,
h1 {
  color: #fff;
}
</style>
